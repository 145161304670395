/*
 * Taken from https://github.com/getsentry/sentry-javascript/blob/f92f39b2c73fa58aff51baed80c4c3974401b97a/packages/core/src/integrations/inboundfilters.ts#L20
 * "Script error." is hard coded into browsers for errors that it can't read.
 * this is the result of a script being pulled in from an external domain and CORS.
 */
export const IGNORE_ERRORS_DEFAULTS = [
  /^Script error\.?$/,
  /^Javascript error: Script error\.? on line 0$/,
  /^ResizeObserver loop completed with undelivered notifications.$/, // The browser logs this when a ResizeObserver handler takes a bit longer. Usually this is not an actual issue though. It indicates slowness.
  /^Cannot redefine property: googletag$/, // This is thrown when google tag manager is used in combination with an ad blocker
  "undefined is not an object (evaluating 'a.L')", // Random error that happens but not actionable or noticeable to end-users.
  'can\'t redefine non-configurable property "solana"', // Probably a browser extension or custom browser (Brave) throwing this error
  "vv().getRestrictions is not a function. (In 'vv().getRestrictions(1,a)', 'vv().getRestrictions' is undefined)", // Error thrown by GTM, seemingly not affecting end-users
  "Can't find variable: _AutofillCallbackHandler", // Unactionable error in instagram webview https://developers.facebook.com/community/threads/320013549791141/
];

/*
 * REF: https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-ignore-errors
 * String values: Partial matches will be filtered out.
 */
export const SENTRY_IGNORE_ERRORS = [
  ...IGNORE_ERRORS_DEFAULTS,
  'Non-Error promise rejection captured with keys', // Unactionable error: https://docs.sentry.io/platforms/javascript/troubleshooting/#events-with-non-error-exception
  'Non-Error promise rejection captured with value', // Unactionable error: https://docs.sentry.io/platforms/javascript/troubleshooting/#events-with-non-error-exception
  'this.plugin.registerEvents is not a function', // Purecloud issue
  "'get' on proxy: property 'javaEnabled' is a read-only and non-configurable data property on the proxy target but the proxy did not return its actual value", // Celebrus issue
  '__puppeteer_evaluation_script__', // Puppeteer/Scraping error
  // Error responses from our BFF. These aren't unexpected errors/issues.
  // eg. 'BFF - createBooking failed!' is thrown when someone doesn't enter valid cc details.
  // But most of these are just the user failing to fetch something.
  // eg. 'BFF - getAppSettings failed!'
  /BFF - \w+ failed!/,
  'Java exception was raised during method invocation', // Android/Facebook issue: https://github.com/getsentry/sentry-javascript/issues/15065
  '@webkit-masked-url:(//hidden/)', // Safari extension issue - https://github.com/getsentry/sentry-javascript/discussions/5875
  'Request aborted', // Axios aborting request when user navigates to another page/refreshes page during API call etc.
];
